// $primary: #a51616;
$primary: #2084B2; /* MAIN COLOR */
$secondary: #FF0000; /* SECONDARY COLOR */
$trm: #FFC47C; /* Trim Color */
$blk: #333; /* DARK GRAY, REASON WHY IT'S SET TO #333 IS SO YOU CAN DARKEN IT IF NEEDED USING darken($blk, 10%); FOR EXAMPLE. YOU COULD ALSO USE 2 VARIABLES INSTEAD */
$wht: #fafafa;
$border-radius: 0px; /* USE THIS TO SET THE BORDER RADIUS FOR BUTTONS */
$footerLinks: #fafafa;


p, ol, ul {font-family: 'Contrail One', cursive;
			  font-size: 1.5em;}


ol, ul {font-family: 'Contrail One', cursive;
			  font-size: 1.15em;}


h1 {font-family: 'Faster One', cursive; 
	font-size: 6em;
	
	@media (max-width: 767px) {
		font-size: 2em;
	}
	
	}

h2 {font-family: 'Faster One', cursive;
	font-size: 3em;}

h3 {font-family: 'Faster One', cursive;
	font-size: 2.75em;}

.logo {width: 100%;
	  max-width: 210px;
	  padding: 10px 0px;
	  
	  @media (max-width: 767px) {
		  max-width: 125px;
		  padding: 10px 10px;

	  }
}

.row {margin-left: 0px;
	  margin-right: 0px;}

.flash {
	display:none;
}

nav {
	z-index: 1000;
}

.navbar-default {
background: rgb(255,255,255); /* Old browsers */
background: -moz-linear-gradient(left,  rgba(255,255,255,1) 25%, rgba(255,255,255,1) 25%, rgba(32,132,178,1) 60%, rgba(255,0,0,1) 100%); /* FF3.6-15 */
background: -webkit-linear-gradient(left,  rgba(255,255,255,1) 25%,rgba(255,255,255,1) 25%,rgba(32,132,178,1) 60%,rgba(255,0,0,1) 100%); /* Chrome10-25,Safari5.1-6 */
background: linear-gradient(to right,  rgba(255,255,255,1) 25%,rgba(255,255,255,1) 25%,rgba(32,132,178,1) 60%,rgba(255,0,0,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#ff0000',GradientType=1 ); /* IE6-9 */
width: 100% !important;
border-bottom: 8px solid $trm;
border-radius: 0px;

}

.navbar .navbar-nav {
    > li > a {
		text-align: center;
		height: 25px;
		display: flex;
		align-items: center;
		border-right: 2px solid $trm;
		color: $wht !important;
		outline: none;


		@media (max-width: 767px) {
		    height: 25px;
		    display: inline-block;
			border-right: 0px;
			color: $wht !important;
		}

	&:focus, &:visited {
	    	background: transparent;
	    	color: $wht;


			@media (max-width: 767px){
				background: transparent;
	    		color: $wht;
			}
}
	
		&:hover {
			color: $primary;

			@media (max-width: 767px){
				color: $primary;
			}


		}
	}
}

.navbar-right {
	margin-top: 50px;
	font-size: 2em;

	@media (max-width: 767px) {
		margin-top: 5px;
		font-size: 1.35em;
	}


}


 
.navbar-toggle {
    margin: 18px 15px 8px 0px;
    border: 1px solid transparent;
    border-radius: 4px;
	
	@media (max-width: 388px) {
		margin-top: 14px;
	}
}

.nav .nav-divider {margin: 0px 0px;}

/** LOGIN FORM **/

@mixin btn1 {
	text-align: center;
	margin: 0 auto;
	border: 1px solid $primary;
	border-radius: $border-radius;
	background: $primary;
	color: $wht;
	padding: 0.5em 2em;

	&:hover {
		background: lighten($primary,10%);
		color: lighten($wht, 10%);
		box-shadow: 0px 0px 3px lighten($blk,10%);
	}
}

@mixin btn2 {
	@include btn1;
	background: darken($wht, 5%);
	color: $primary;
	border: none;

	&:hover {
		background: $wht;
		color: $primary;
		border: none;
	}
}

.modal-dialog {
	max-width: 300px;
	text-align: center;
	margin: 6em auto;
	
	.close {display: none;}

	.modal-content {
		color: $primary;
		
		h2 {
			text-align:center;
		}
	}

	input {
		border: none;
		border-bottom: 1px solid darken($primary,20%);
		text-align: center;
	}
	
	button {
		background: transparent;
		color: $wht;
		display: block;
		border:none;
		padding: 1em 2em;
		margin: 0 auto;

		&:hover {
			background: transparent;
			color: #fff;
			box-shadow: none;
			text-shadow: 0px 0px 3px $blk;
			border:none;
		}
	}
	
	input[type="submit"] {
		@include btn1;
		display: block;
		width: 75%;	
	}

}

.modal-header, .modal-footer {
	background: $primary;
	color: $wht;
}

input#username {
	margin-bottom: 20px;
}

/** END LOGIN FORM **/

footer {
	padding: 50px 0px 20px;
	background: $primary;
	color: $footerLinks;

	a {
		color: $footerLinks;

		&:hover {
			color: lighten($footerLinks, 10%);
		}
	}

	p {font-size: 1em;}
}

input#username, input#password {
	width: 100%;
}

@media (max-width: 1024) {
	input#username, input#password {
  		font-size: 16px;
	}
}

select {
    -moz-appearance: none;
    text-indent: 0.01px;
    text-overflow: '';
}

.sticky-wrapper {height: auto !important;}

.navbar {margin-bottom: 0px;}

/* ============= HEADER ========= */

.header {background: url('../img/banner.jpg');
		 background-repeat: no-repeat;
		 background-position: 50% 10%;
		 background-size: cover;
 		 -moz-background-size: cover;
		 -o-background-size: cover;
  		 -webkit-background-size: cover;
		
		@media (max-width: 1024px) {
			background-position: 40% 10%;
		}

		@media (max-width: 767px) {
			background-position: 0% 10%;
		}
}

.header-overlay {
  background-color: rgba(31, 31, 31, 0.75);
  padding: 150px 0px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);

  @media (max-width: 1024px) {
	  padding: 200px 0px;
  }


  @media (max-width: 767px) {
	  padding: 50px 0px;
  }

}

.header h1 {color: $wht;
			text-align: center;
			text-shadow: 3px 3px $trm;
			font-size: 8em;


			@media (max-width: 1024px) {
				font-size: 4.75em;
			}

			@media (max-width: 767px) {
				font-size: 4.5em;
			}
}

.header p {color: $wht;
		   line-height: 45px;
		   letter-spacing: 2px;
		   font-size: 1.75em;

		   	@media (max-width: 1024px) {
				font-size: 1.25em;
				text-align: center;
			}

		   @media (max-width: 767px)  {
			   text-align: center;
		   }

}


/* ========= BUTTONS ============ */

.btn-download,
.btn-download:focus,
.btn-download:active,
.btn-download:visited
{
    background-color: $primary;
    border-color: $wht;
	   color: $wht;
padding: 10px 20px;
    font-size: 19px;
    text-transform: uppercase;
    border-radius: 0px;
    transition: all 1s;
    -webkit-transition: all 1s;
    -moz-transition: all 1s;
     -o-transition: all 1s;
}

.btn-download:hover {
    background-color: $secondary;
    color: $wht;

}

.btn-download {
    margin-bottom: 15px !important;
    display: block;
    margin: 0 auto;
    max-width: 175px;
	margin-top: 50px;


	@media (max-width: 767px) {

		margin-top: 10px;
	}



	@media (max-width: 1024px) {

		margin-top: 10px;
	}
}

/* ========= SECTION ONE =========== */

.section-one {
			background: url('../img/speed-bg.png');
		 background-repeat: no-repeat;
		 background-position: 100% 50%;
		 background-size: cover;
 		 -moz-background-size: cover;
		 -o-background-size: cover;
		-webkit-background-size: cover;
		text-align: center;

		@media (max-width: 767px) {
			background-position: 0% 10%;
		}

}


.section-one-overlay {
  background-color: rgba(31, 31, 31, 0.75);
  padding: 50px 0px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);

}

.section-one h1 {color: $wht;
				 text-shadow: 1px 1px $trm;
				 
				 @media (max-width: 767px) {
					 font-size: 4em;
				 }
				 
				 }

.section-one h1:after {
    content: " ";
    border:1px solid $trm;
    margin-top: 25px;
    margin-bottom: 25px;
    display: block;
    max-width: 100%;
	text-align: center;
}

.section-one h3 {color: $wht;
				 text-shadow: 1px 1px $trm;
				
				@media (max-width: 1199px) {
					 font-size: 2.25em;
				 }
				
				@media (max-width: 767px) {
					 font-size: 2.5em;
				 }
				 		 
				 
		}

.section-one h3:after {
    content: " ";
    border:1px solid $trm;
    margin-top: 15px;
    margin-bottom: 15px;
    display: block;
    max-width: 100%;
}

.section-one p {color: $wht;}


.section-one img {width: 100%;
				  max-width: 270px;
				  display: block;
				  margin: 0px auto;
				  border: 5px solid $trm;}



/* =========== SECTION TWO ============= */

.section-two {background: url('../img/section-two-banner.jpg');
		 background-repeat: no-repeat;
		 background-position: 50% 0%;
		 background-size: cover;
 		 -moz-background-size: cover;
		 -o-background-size: cover;
		-webkit-background-size: cover;
		text-align: center;

}


.section-two-overlay {
  background-color: rgba(31, 31, 31, 0.5);
  padding: 150px 0px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);

  	
	  @media (max-width: 767px) {
		  padding: 25px 0px;
	  }

}

.section-two h1 {color: $wht;
				 
				 @media (max-width: 767px) {
					 font-size: 4em;

				 }

				}

.section-two h1:after {
    content: " ";
    border:1px solid $trm;
    margin-top: 15px;
    margin-bottom: 15px;
    display: block;
    max-width: 100%;
}

.section-two p {color: $wht;}

/* =========== SECTION THREE ============ */

.section-three {padding: 50px 0px;


				@media (max-width: 1024px) {
					 padding: 10px 0px;
					 text-align: center;

				 }

				@media (max-width: 767px) {
					 padding: 10px 0px;
					 text-align: center;

				 }

}

.section-three h1 {color: $primary;
					text-align: center; 
					padding-bottom: 25px;



				@media (max-width: 767px) {
					 font-size: 4em;
				 }
	}

.section-three h1:after {
    content: " ";
    border:1px solid $trm;
    margin-top: 15px;
    margin-bottom: 15px;
    display: block;
    max-width: 100%;
}

.section-three h2 {color: $primary;
					font-size: 2em;
				 }

.section-three h2:after {
    content: " ";
    border:1px solid $trm;
    margin-top: 15px;
    margin-bottom: 15px;
    display: block;
    max-width: 350px;

	@media (max-width: 1024px) {
		max-width: 100%;
	}
}

.section-three p {color: $blk;}

.section-three img {width: 100%;
				  max-width: 400px;
				  display: block;
				  margin: 0px auto;
				  border: 5px solid $trm;}


/* =========== SECTION FOUR ============ */

.section-four {
			  text-align: center;
			  background: url('../img/section-four-banner.jpg');
		 background-repeat: no-repeat;
		 background-position: 50% 50%;
		 background-size: cover;
 		 -moz-background-size: cover;
		 -o-background-size: cover;
		-webkit-background-size: cover;
		text-align: center;
}

.section-four-overlay {
  background-color: rgba(31, 31, 31, 0.5);
  padding: 100px 0px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);


  				@media (max-width: 767px) {
					 padding: 10px 0px;
					 text-align: center;

				 }

}

.section-four h1 {color: $wht;
				
				@media (max-width: 767px) {
					 font-size: 3.5em;
				 }

				 }

.section-four h1:after {
    content: " ";
    border:1px solid $trm;
    margin-top: 25px;
    margin-bottom: 25px;
    display: block;
    max-width: 100%;
	text-align: center;
}

.section-four h3 {color: $primary;
				 }

.section-four h3:after {
    content: " ";
    border:1px solid $trm;
    margin-top: 15px;
    margin-bottom: 15px;
    display: block;
    max-width: 100%;
}

.section-four p {color: $wht;}


.section-four img {width: 100%;
				  max-width: 270px;
				  display: block;
				  margin: 0px auto;
				  border: 5px solid $trm;}